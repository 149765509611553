<template>
  <LiefengContent>
    <template #title>用户画像</template>
    <template v-slot:toolsbarLeft>
        <DatePicker 
            @on-change='changeTime'
            type="daterange" 
            show-week-numbers placement="bottom-end" 
            placeholder="选择日期" 
            class="picker"
            v-model="timeData"
        ></DatePicker>
    </template>
    <template v-slot:toolsbarRight>
        <Timedropdown @changeTime='getTime' ></Timedropdown>
        <Button @click="$router.push('/appletsurvey')">概况</Button>
        <Button @click="$router.push('/accesstrends')">访问趋势</Button>
        <Button @click="$router.push('/accessspread')">访问分布</Button>
        <Button @click="$router.push('/accesskeep')">访问留存</Button>
        <Button @click="$router.push('/accesspage')">访问页面</Button>
        <Button @click="$router.push('/userportrait')"  type="primary">用户画像</Button>
    </template>
    <template #contentArea>
      <Card>
        <p slot="title">性别分布</p>
        <div style="padding-bottom:16px">
          <Select v-model="type1" style="width:200px;margin-right:10px" @on-change='changeType1'>
            <Option v-for="item in typeList1" :value="item.value" :key="item.value">{{ item.label }}</Option>
          </Select>
          <Select v-model="time1" style="width:200px;margin-right:10px" @on-change='changeTime1'>
            <Option v-for="item in timeList1" :value="item.value" :key="item.value">{{ item.label }}</Option>
          </Select>
          <span>{{statrTime1}}-{{endTime}}</span>
        </div>
        <div class="box">
          <div class="left">
            <Ring :data="sexData" :extend="sexExtend"></Ring>
          </div>
          <div class="right">
            <Table :data="sexTData" :columns="sexColumn"></Table>
          </div>
        </div>
      </Card>
      <Card style="margin-top:20px">
        <p slot="title">年龄分布</p>
        <div style="padding-bottom:16px">
          <Select v-model="type2" style="width:200px;margin-right:10px" @on-change='changeType2'>
            <Option v-for="item in typeList2" :value="item.value" :key="item.value">{{ item.label }}</Option>
          </Select>
          <Select v-model="time2" style="width:200px;margin-right:10px"  @on-change='changeTime2'>
            <Option v-for="item in timeList2" :value="item.value" :key="item.value">{{ item.label }}</Option>
          </Select>
          <span>{{statrTime2}}-{{endTime}}</span>
        </div>
        <div class="box">
          <div class="left">
            <Ring :data="sexData" :extend="sexExtend"></Ring>
          </div>
          <div class="right">
            <Table :data="sexTData" :columns="sexColumn"></Table>
          </div>
        </div>
      </Card>
    </template>
  </LiefengContent>
</template>

<script>
import LiefengContent from "./children/LiefengContent";
import Timedropdown from './children/timedropdown'
import Ring from "v-charts/lib/ring.common";
export default {
  data() {
    return {
      statrTime1:this.$core.formatDate(new Date(new Date().getTime()-1000*60*60*24*1), "yyyy-MM-dd"),
      statrTime2:this.$core.formatDate(new Date(new Date().getTime()-1000*60*60*24*1), "yyyy-MM-dd"),
      endTime:this.$core.formatDate(new Date(new Date().getTime()-1000*60*60*24*1), "yyyy-MM-dd"),
      type1:'0',
      typeList1:[
        {
            value: '0',
            label: '活跃用户数'
        },
        {
            value: '1',
            label: '新增用户数'
        },
      ],
      time1:'0',
      timeList1:[
        {
          value:'0',
          label:'近1天'
        },
        {
          value:'1',
          label:'近7天'
        },
        {
          value:'2',
          label:'近30天'
        },
      ],
      sexData: {
        columns: ["性别", "人数"],
        rows: [
          {性别:'男',人数:5000},
          {性别:'女',人数:4000},
          {性别:'其他',人数:0},
        ],
      },
      sexColumn: [
            {
              title: "性别",
              key: "gander",
              minWidth: 200,
              align: "center",
            },
            {
              title: "人数",
              key: "value",
              minWidth: 200,
              align: "center",
            },
            {
              title: "占比",
              key: "ext",
              minWidth: 200,
              align: "center",
            },
          ],
      sexTData: [
        {gander:'男',value:5000,ext:'60%'},
        {gander:'女',value:4000,ext:'40%'},
        {gander:'其他',value:0,ext:'0%'}
      ],


      type2:'0',
      typeList2:[
        {
            value: '0',
            label: '活跃用户数'
        },
        {
            value: '1',
            label: '新增用户数'
        },
      ],
      time2:'0',
      timeList2:[
        {
          value:'0',
          label:'近1天'
        },
        {
          value:'1',
          label:'近7天'
        },
        {
          value:'2',
          label:'近30天'
        },
      ],
    };
  },
  async created(){
  },
  methods: {
   getTime(val){
      console.log(val)
      this.timeData = val
    },
    changeTime(val){
      console.log(val)
      this.timeData[0] = val[0]
      this.timeData[1] = val[1]
      console.log(this.timeData)
    },
    changeTime1(val){
      if(val==0){
        this.statrTime1=this.$core.formatDate(new Date(new Date().getTime()-1000*60*60*24*1), "yyyy-MM-dd")
      }else if(val == 1){
        this.statrTime1=this.$core.formatDate(new Date(new Date().getTime()-1000*60*60*24*7), "yyyy-MM-dd")
      }else{
        this.statrTime1=this.$core.formatDate(new Date(new Date().getTime()-1000*60*60*24*30), "yyyy-MM-dd")
      }
    },
    changeTime2(){
      if(val==0){
        this.statrTime2=this.$core.formatDate(new Date(new Date().getTime()-1000*60*60*24*1), "yyyy-MM-dd")
      }else if(val == 1){
        this.statrTime2=this.$core.formatDate(new Date(new Date().getTime()-1000*60*60*24*7), "yyyy-MM-dd")
      }else{
        this.statrTime2=this.$core.formatDate(new Date(new Date().getTime()-1000*60*60*24*30), "yyyy-MM-dd")
      }
    },
  },
  components: {
    LiefengContent,
    Timedropdown,
    Ring
  },
};
</script>
    
<style scoped lang='less'>
/deep/.ivu-card-head{
    border:none
}
/deep/.ivu-card-body{
  padding:0 16px 16px 16px
}
.picker{
    position: absolute;
    right: 0;
}
.box {
  display: flex;
  .left {
    width: 50%;
  }
  .right {
    margin-left: 20px;
    flex: 1;
  }
}
</style>